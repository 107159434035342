import Head from 'next/head'
import 'semantic-ui-css/semantic.min.css'
import 'nprogress/nprogress.css'
import React from 'react'
import { injectPolyfillIO } from '../../utils'

export default React.memo(() => (
  <Head>
    <title>Köyden Gelsin</title>
    <meta charSet='utf-8' />
    <meta name='viewport' content='width=device-width, initial-scale=1, user-scalable=0' />
    {/* Open Graph Data */}
    <meta property='og:site_name' content='Köyden Gelsin' />
    <meta property='og:type' content='website' />
    <meta property='og:title' content='Köyden Gelsin' />
    <meta property='og:url' content='https://www.koydengelsinn.com/' />
    <meta
      property='og:description'
      content='Doğal ürünler.'
    />{' '}
    <meta name='theme-color' content='#009E7F' />
    <meta name='apple-mobile-web-app-capable' content='yes' />
    <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
    <meta property='og:image' content='' />
    {/* Twitter */}
    <meta name='twitter:card' content='summary' />
    {/* eslint-disable-line max-len */}
    <meta name='twitter:image:src' content='' />
    {/* eslint-disable-line max-len */}
    <meta name='msvalidate.01' content='' />
    <meta property='fb:app_id' content='' />
    <meta property='product:category' content='418' />
    <meta name='google-site-verification' content='U_EaIQJIEvzq8tRDgBzMNhbW_bKigWUY208m9IWJe9c' />
    <link rel='preconnect' href='https://fonts.googleapis.com' />
    <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
    <link href='https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap' rel='stylesheet' />
    {/* <!--[if IE]> */} <link rel='shortcut icon' href='/static/favicon/favicon.ico' /> {/* <![endif]--> */}
    <meta name='msapplication-TileImage' content='/static/favicon/favicon-32x32.png' />
    <link rel='apple-touch-icon' href='/static/favicon/apple-touch-icon.png' />
    <link rel='android-touch-icon' href='/static/favicon/apple-touch-icon.png' />
    <link rel='icon' type='image/png' href='/static/favicon/android-chrome-192x192.png' sizes='128x128' />
    <link rel='apple-touch-icon-precomposed' sizes='144x144' href='/static/favicon/android-chrome-192x192.png' />
    <link rel='apple-touch-icon-precomposed' sizes='114x114' href='/static/favicon/android-chrome-192x192.png' />
    <link rel='apple-touch-icon-precomposed' sizes='72x72' href='/static/favicon/android-chrome-192x192.png' />
    <link rel='apple-touch-icon-precomposed' sizes='57x57' href='/static/favicon/android-chrome-192x192.png' />
    <script async src='https://www.googletagmanager.com/gtag/js?id=G-XPXCT4P0SM' />
    {/* eslint-disable-next-line react/no-danger */}
    <script
      dangerouslySetInnerHTML={{
        __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-K6S2ZLS');
              `
      }}
    />
    <script
      dangerouslySetInnerHTML={{
        __html:`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-XPXCT4P0SM');
          `
      }}
    />
    <script
      dangerouslySetInnerHTML={{
        __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${332498537996644});
              `
      }}
    />

    <noscript>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        height='1'
        width='1'
        style={{ display: 'none' }}
        src={`https://www.facebook.com/tr?id=${332498537996644}&ev=PageView&noscript=1`}
      />
    </noscript>
    {injectPolyfillIO()}
  </Head>
))
